import React from 'react';
import articleImage from '../assets/rponline.png'; // Pfad zum Screenshot des Artikels
import wood from '../assets/wood-new.jpg'; 

const Article = () => {
  const articleUrl = "https://rp-online.de/nrw/staedte/duesseldorf/duesseldorf-das-restaurant-hasseler-fass-eroeffnet-neu_aid-105596731";

  return (
    <section id='article' className='bg-[#1b1b1b]'
    style={{
        backgroundImage: `linear-gradient(rgba(20, 10, 4, 0.8), rgba(20, 10, 4, 0.8)), url(${wood})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
      <div className="container mx-auto py-6">
        <h2 className="text-3xl font-bold mb-2 text-white">Artikel Rheinische Post</h2>
        <div className="article-embed self-center">
          <img src={articleImage} alt="Article Screenshot" className='w-full h-auto' />
          <a href={articleUrl} target="_blank" rel="noopener noreferrer" className='inline-block mt-4 px-6 py-2 bg-white text-black rounded font-bold text-3xl'>Artikel lesen</a>
        </div>
      </div>
    </section>
  );
};

export default Article;