import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import wood from '../assets/wood-new.webp';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const apiKey = process.env.REACT_APP_XCTOKEN;

  useEffect(() => {
    const options = {
      method: 'GET',
      url: 'https://nocodb.gastroleben.duckdns.org/api/v1/db/data/noco/p8tnjl1b0vtybbn/Gallery/views/Fotos',
      params: {offset: '0', limit: '25', where: ''},
      headers: {
        'xc-token': `${apiKey}`,
      }
    };

    axios.request(options).then(function (response) {
      // Stellen Sie sicher, dass response.data.list das erwartete Array ist
      if (response.data.list && Array.isArray(response.data.list)) {
        const targetData = response.data.list.find(item => item.Id === 1);
        if (targetData && Array.isArray(targetData.Media)) {
          setImages(targetData.Media.map(img => ({ src: 'https://nocodb.gastroleben.duckdns.org/' + img.path })));
        } else {
          console.error('Unexpected response data:', response.data);
        }
      } else {
        console.error('Unexpected response data:', response.data);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }, []); // Schließende Klammer für useEffect hinzugefügt

  return (
    <section id='gallery' className='bg-[#1b1b1b]'
      style={{
        backgroundImage: `linear-gradient(rgba(20, 10, 4, 0.8), rgba(20, 10, 4, 0.8)), url(${wood})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-2 text-white">Gallery</h2>
        <div className="mx-auto max-w-screen-md">
          <Carousel images={images} className='md:h-full md:w-[1200px]' style={{ height: 500 }} />
        </div>
      </div>
    </section>
  );
};

export default Gallery;