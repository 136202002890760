import { React, useRef } from 'react';
import logo from '../assets/logo-transparent.png'; // Pfad zu deinem Logo
import { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { motion, useInView } from 'framer-motion';
import wood from '../assets/wood-new.webp';

const navigation = [
  { name: 'Speisekarte', href: '/Speisekarte' },
  { name: 'Getränkekarte', href: '/Getraenkekarte' },
  { name: 'Wochenkarte', href: '/Wochenkarte' },
  { name: 'Kontakt', href: '..#contact' },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [navClass, setNavClass] = useState("nav-down");  

  // Nav bekommt Hintergrund bei Scrollen
  const [scrolled, setScrolled] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 10 ) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  let navbarClasses=['navbar', 'inset-x-0', 'fixed', 'top-0', 'z-50', 'h-max'];
  if (scrolled) {
    navbarClasses.push('scrolled', 'bg-[#2b170b9e]');
  } else {
    navbarClasses.push('bg-transparent');
  }

  
  useEffect(() => {
    const handleScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop){
        setNavClass("nav-up");
      } else {
        setNavClass("nav-down");
      }
      setLastScrollTop(st <= 0 ? 0 : st);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

 

  return (
<section ref={ref} id='header' className="h-28 absolute">
    <div className="bg-[#00000000] h-auto">
      <header className={`${navbarClasses.join(' ')} ${navClass}`}>
        <nav className="bg-[#00000000] flex items-center shadow-md justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-16 w-auto"
                src={logo}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => {
                setMobileMenuOpen(true)
                
              }
              }
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-12 w-12" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12 ">
        
          
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-3xl font-semibold leading-6 text-white">
                {item.name}
              </a>
            ))}
          </div>
          
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
           
          </div>
        </nav>
      <motion.div style={{
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
            }}
            >
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel
           style={{
            backgroundImage: `linear-gradient(rgba(20, 10, 4, 0.8), rgba(20, 10, 4, 0.8)), url(${wood})`
           }}
           className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#e" className="-m-1.5 p-1.5">
                <span className="sr-only"></span>
                <img
                  className="h-16 w-auto"
                  src={logo}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-16 w-16" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-8 text-3xl text-center font-semibold leading-7 text-white hover:bg-gray-50 hover:text-gray-950" 
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
        </motion.div>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >

        </div>

        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          
        </div>
      </div>
    </div>
</section>
  )
}