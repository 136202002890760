import { React, useRef } from 'react';
import wood from '../assets/wood-new.webp'; 
import innenraum from '../assets/kegel01.webp';
import innenraum2 from '../assets/bar02.webp';
import { motion, useInView } from 'framer-motion';

const GridSection = () => {
   //Transformationen für die Textbereiche


  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  
  return (
  <section ref={ref} id='grid' className='pt-20 -mt-20 '
  style={{
    backgroundImage: `linear-gradient(rgba(20, 10, 4, 0.8), rgba(20, 10, 4, 0.8)), url(${wood})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }}> 
    <div className="container mx-auto p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12">

          {/* Textbereich 1 */}
          <motion.div 
            className="p-4 rounded self-center order-1 md:order-1 lg:order-1" 
            style={{
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              
            }}
          >
            <h2
             className="relative text-white font-bold lg:text-6xl text-2xl mb-3 z-10"
             >Herzlich Willkommen</h2>
            <p className='z-11 text-white lg:text-3xl text-xl font-arial'>Entdecken Sie die Wärme deutscher
               Gastfreundschaft in unserem gemütlichen Restaurant, 
               das Tradition und Moderne stilvoll vereint.
               Unsere einladende Atmosphäre lädt ein zum Verweilen,
               Genießen und Zusammensein.
            </p>
            
          </motion.div>
          

          {/* Textbereich 2 */}
          <motion.div 
            className="p-4 rounded order-latest" 
            style={{
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
               }}
          >
            <img className=""
              src={innenraum}
              alt='Innenraum'
              >

            </img>
   
          </motion.div>

          {/* Bildbereich 3 */}
          <motion.div 
            className="rounded overflow-hidden self-center " 
            style={{ 
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
               }}
          >
            <h2 className="relative text-white font-bold lg:text-6xl text-2xl mb-3">Unser Erbe</h2>
            <p className='text-white lg:text-3xl text-xl font-arial'>Unsere Rezepte sind tief verwurzelt in der deutschen
               Kochtradition, verfeinert durch Generationen.
               Jedes Gericht erzählt die Geschichte der reichen
               kulinarischen Erbschaft, die wir mit Stolz bewahren und
               an Sie weitergeben.
            </p>
            
          </motion.div>
          

          {/* Textbereich 4 */}
          <motion.div 
            className="p-4 rounded order-3 md:order-3 lg:order-3" 
            style={{ 
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
          >
            <img className=""
              src={innenraum2}
              alt='Innenraum2'
              >

            </img>
          </motion.div>

          {/* ... Weitere Bereiche ... */}
        </div>
      </div>
    </section>
  );
};


export default GridSection;