import { useState, useEffect } from 'react';
import React from 'react';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



//import pdfFile from './assets/Speisekarte.pdf'; // Stelle sicher, dass der Pfad zu deiner PDF-Datei korrekt ist.



function Wochenkarte() {
  const [pdfUrl, setPdfUrl] = useState(null);
  const apiKey = process.env.REACT_APP_XCTOKEN;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const options = {
      method: 'GET',
      url: 'https://nocodb.gastroleben.duckdns.org/api/v1/db/data/noco/p8tnjl1b0vtybbn/Wochenkarte/views/Gallery',
      params: {offset: '0', limit: '25', where: ''},
      headers: {
        'xc-token': `${apiKey}`,
        'responseType': 'arraybuffer'
      }
    };

    

    axios.request(options).then(function (response) {

      // Stellen Sie sicher, dass response.data.list das erwartete Array ist
      if (response.data.list && Array.isArray(response.data.list)) {
        const targetData = response.data.list[0];
        if (targetData && Array.isArray(targetData.file)) {
          const pdfPath = 'https://nocodb.gastroleben.duckdns.org/' + targetData.file[0].path;
          setPdfUrl(pdfPath);
        } else {
          console.error('Unexpected response data:', response.data);
        }
      } else {
        console.error('Unexpected response data:', response.data);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }, []); // Schließende Klammer für useEffect

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="App md:flex md:justify-center md:items-center">
      {pdfUrl && (
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      )}
    </div>
  );
}

export default Wochenkarte;