import Header from './components/header.js';
import Feature from './components/feature.js';
import Parallax from './components/parallax.js';
import Grid from './components/grid.js';
//import Cards from './components/cards.js';
import Parallax2 from './components/parallax2.js';
import Contact from './components/Contact.js';
import Gallery from './components/gallery.js';
import Article from './components/article.js';

import wood from './assets/wood.jpg';

import './App.css';
import Footer from './components/footer.js';

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import logoBild from './assets/logo-transparent.png'; // Importieren Sie Ihr Logo

function Home() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2500); // 2,5 Sekunden Ladezeit
    return () => clearTimeout(timer);
  }, []);

  return (
    loading ?
      <div
        className="flex justify-center items-center h-screen bg-cover" // Zentrierung des Logos
        style={{ backgroundImage: `linear-gradient(rgba(20, 10, 4, 0.8), rgba(20, 10, 4, 0.8)), url(${wood})`}}
      >
        <motion.img
          className='h-auto w-auto'
          src={logoBild}
          alt="Logo" // Logo als Bild
          animate={{
            rotate: [180, 360],
            //geschwindigkeit der Rotation
            transition: { duration: 2.5, ease: "easeInOut" },
          }}
          transition={{
            duration: 2.0,
            ease: "easeInOut",
          }}
        />
      </div>
    :
    <div className="App">
       <Header/>
       <Feature />
       <Parallax />
       <Grid />
       <Parallax2 />
       <Gallery />
       <Parallax />
       <Article />
       <Parallax2 />
       <Contact />
       <Parallax />
       <Footer />
    </div>
  );
}

export default Home;
