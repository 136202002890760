//import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { React, useRef } from 'react';
import Logo from '../assets/Innenraum05.webp'
import wood from '../assets/wood-new.webp'; 
import '../App.css';
import { motion, useInView } from 'framer-motion';



const FeatureSection = () => {

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  
  return (
  <section ref={ref} id='feature' className='bg-[#140a04] pt-20 -mt-20 '
    style={{
      backgroundImage: `linear-gradient(rgba(20, 10, 4, 0.8), rgba(20, 10, 4, 0.8)), url(${wood})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }}>
    <motion.div className="relative isolate overflow-hidden container mx-auto px-6 py-20 lg:overflow-visible lg:px-0"
      style={{
        transform: isInView ? "none" : "translateX(-100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.3s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s"
      }}
    >
<div className="flex flex-wrap justify-between mx-auto lg:my-12 my-1 max-w-7xl lg:max-w-max lg:mx-5 lg:self-center">
  <div className="w-full lg:w-3/5 xl:w-1/2 xl:h-auto self-center">
    <h1 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-4xl lg:text-9xl">Herzlich Willkommen</h1>
    <p className="mt-6 lg:text-3xl font-arial text-xl leading-8 text-white">
    Genießen Sie eine kulinarische Reise in unserem Hause. Im <strong>Hasslerfass</strong> bieten wir Ihnen ein einzigartiges Ambiente, in dem Sie entspannen und unsere vielfältige Speisekarte erkunden können. Unsere Leidenschaft für exzellente Küche spiegelt sich in jedem Gericht wider, das wir mit Sorgfalt und frischen Zutaten zubereiten.
    </p>
    <div className="mt-6 text-base font-semibold leading-7">
      <a href="/Speisekarte" className="bg-[#35230928] hover:bg-white hover:text-gray-600 hover:shadow-md text-white font-bold text-4xl py-2 px-4 rounded">
        Speisekarte
      </a>
    </div>
  </div>
  <div className="lg:w-2/5 lg:sticky ml-7 mr-7 mt-6 lg:top-4 lg:overflow-hidden">
    <img
      className="w-72 max-w-none rounded shadow-xl ring-1 ring-gray-400/10 sm:w-[37rem]"
      src={Logo}
      alt=""
    />
  <ul className="mt-6 text-base text-stone-300 font-semibold list-none">
   <li className="mt-6 text-base text-stone-300 font-semibold list-none">
      <a href="#contact" className="bg-[#35230928] hover:bg-white hover:text-gray-600 hover:shadow-md text-white font-bold text-4xl py-2 px-4 rounded">
        Kontakt
      </a>
    </li>
    <li className='mt-3 font-arial'>Telefon: <a href='tel:021192966644'> 0211 929 666 44 </a></li>
        <li className='font-arial'>E-Mail:
            <a href="mailto:hasslerfass@gastroleben.com" className="text-gray-300 hover:underline text-xl"> hasslerfass@gastroleben.com</a>
          </li>
  </ul>
  </div>
</div>
    </motion.div>
  </section>
  );
};

export default FeatureSection;