import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Speisekarte from './Speisekarte';
import Getraenkekarte from './Getraenkekarte';
import Wochenkarte from './Wochenkarte';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Speisekarte" element={<Speisekarte />} />
        <Route path="/Getraenkekarte" element={<Getraenkekarte />} />
        <Route path="/Wochenkarte" element={<Wochenkarte />} />
        <Route path="/Impressum" element={<Impressum />} />
        <Route path="/Datenschutz" element={<Datenschutz />} />
        {/* Füge weitere Routen nach Bedarf hinzu */}
      </Routes>
    </Router>
  );
};

export default App;
