import React from 'react';
import { Link } from 'react-router-dom'; // Importieren Sie Link von react-router-dom

const Impressum = () => {
    return (
        <section id='impressum' className='bg-[#1b1b1b] h-[100vh]'>
            <div className="container mx-auto py-6 text-white">
                <Link to="/" className="inline-block mb-4 font-arial text-white">← Zurück zur Homepage</Link>
                <h2 className="text-3xl font-arial font-bold mb-2">Impressum</h2>
                <br />
                <p className='text-3xl font-arial'>Verantwortungsbereich​</p>
                <p className='font-arial'>Das Impressum gilt nur für die Internetpräsenz unter der Adresse: www.hasslerfass2.de</p>
                {/* Rest des Textes */}
                <br />
                <p className='text-3xl font-arial'>Abgrenzung​</p>
                <p className='font-arial'>Die Web-Präsenz ist Teil des WWW und dementsprechend mit fremden, sich jederzeit wandeln könnenden Web-Sites verknüpft, die folglich auch nicht diesem Verantwortungsbereich unterliegen und für die nachfolgende Informationen nicht gelten. Dass die Links weder gegen Sitten noch Gesetze verstoßen, wurde genau ein mal geprüft, bevor sie hier aufgenommen wurden.</p>
                <br />
                <p className='text-3xl font-arial'>Verantwortliche Ansprechperson</p>
                <p className='font-arial'>Gastroleben Events & More GmbH <br />
                    Geschäftsführer: Pierre Krekel
                    <br />
                    Graf-Adolf-Straße 41
                    <br />
                    40210 Düsseldorf</p>
                {/* Rest des Textes */}
                <br />
                <p className='text-3xl font-arial'>Urheberschutz und Nutzung</p>
                <p className='font-arial'>Der Urheber räumt Ihnen ganz konkret das Nutzungsrecht ein, sich eine private Kopie für persönliche Zwecke anzufertigen. Nicht berechtigt sind Sie dagegen, die Materialien zu verändern und/oder weiter zu geben oder gar selbst zu veröffentlichen. </p>
                {/* Rest des Textes */}
            </div>
        </section>
    );
};

export default Impressum;