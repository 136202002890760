import '../App.css';
import background from '../assets/Innenraum04.JPG'; // Pfad zu deinem Hintergrundbild


const ParallaxSection = () => {
  return (
  <section id='parallax' className='pt-20 -mt-20' > 
    <div className="relative overflow-hidden">
      <div
        className="parallax-background bg-fixed bg-no-repeat bg-cover bg-center h-24"
        style={{ backgroundImage: `url(${background})` }}
      >
        {/* Overlay-Inhalt */}
      
      </div>
    </div>
  </section>
  );
};

export default ParallaxSection;