import React from 'react';
import wood from '../assets/wood-new.webp';

const Contact = () => {
  const address = "";

  return (
  <section id='contact' className='bg-[#1b1b1b]'style={{
    backgroundImage: `linear-gradient(rgba(20, 10, 4, 0.8), rgba(20, 10, 4, 0.8)), url(${wood})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }}>
    <div className="container mx-auto py-6">
      <h2 className="text-3xl font-bold mb-2 text-white">Kontakt</h2>
      <p className="mb-4 text-white">{address}</p>
      <ul className='mb-4 text-white'>
        <li className='font-arial'>Telefon: <a href='tel:021192966644'> 0211 929 666 44 </a></li>
        <li className='font-arial'>E-Mail:
            <a href="mailto:hasslerfass@gastroleben.com" className="text-gray-300 hover:underline text-xl"> hasslerfass@gastroleben.com</a>
          </li>
        </ul>  
      <div className="google-map self-center">
        <iframe title='Google Maps' className='self-centers w-full h-96'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2501.356136770704!2d6.870412554490018!3d51.17565861633746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8cd370f30822d%3A0x17fb1a74c1367e4c!2sRoute%209%20im%20Hasseler%20Fass!5e0!3m2!1sde!2sde!4v1706517857736!5m2!1sde!2sde"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="async"
        ></iframe>
      </div>
    </div>
  </section>
  );
};

export default Contact;