import '../App.css';
import logo from '../assets/logo-transparent.png';
import wood from '../assets/wood-new.webp'; 

export default function footer() {
  return (
    <section id='footer' className="h-28"
     > 
    <footer className=""style={{
        backgroundImage: `linear-gradient(rgba(20, 10, 4, 0.8), rgba(20, 10, 4, 0.8)), url(${wood})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
    <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flexs">
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
             <div>
             <img src={logo} className="h-24 me-3" alt="HasslerFass" />
             </div>
              <div>
                  <h2 className="mb-6 text-sm font-semibold text-white uppercase">Resources</h2>
                  <ul className="text-white font-medium">
                      <li className="mb-4">
                          <a href="#e" className="hover:underline">über uns</a>
                      </li>
                      <li>
                          <a href="#e" className="hover:underline">Kontakt</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6 text-sm font-semibold text-white uppercase">Follow us</h2>
                  <ul className="text-white font-medium">
                      <li className="mb-4">
                          <a href="https://m.facebook.com/profile.php?id=61554601178308" className="hover:underline ">Facebook</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6 text-sm font-semibold text-white uppercase">Legal</h2>
                  <ul className="text-white font-medium">
                      <li className="mb-4">
                          <a href="/impressum" className="hover:underline">Impressum</a>
                      </li>
                      <li>
                          <a href="/datenschutz" className="hover:underline">Datenschutz</a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-white sm:text-center">made with ❤️ by philipp
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            {/*  Hier die Icons rein */}
          </div>
      </div>
    </div>
</footer>
    </section>
  )
}